import React, { useContext, useEffect, useState } from "react";
import { ClubAlert } from "../types/Alerts";
import { getDplAlerts } from "../clients/DPLClient";

type DplAlertContextType = {
  alerts: ClubAlert[];
  getAlerts?: () => Promise<any>;
};

const DplAlertContext = React.createContext<DplAlertContextType | undefined>(undefined);

const DplAlertProvider: React.FC = ({ children }) => {
  const [alerts, setAlerts] = useState<ClubAlert[]>([]);

  const getAlerts = async () => {
    const result: ClubAlert[] = await getDplAlerts();
    if (result && result.length) {
      setAlerts(
        result.sort((a: ClubAlert, b: ClubAlert) => {
          if (a.createdDate > b.createdDate) {
            return -1;
          } else if (a.createdDate < b.createdDate) {
            return 1;
          } else {
            return 0;
          }
        }),
      );
    } else {
      setAlerts([]);
    }
  };

  useEffect(() => {
    getAlerts();
  }, []);

  return <DplAlertContext.Provider value={{ alerts, getAlerts }}>{children}</DplAlertContext.Provider>;
};

const useDplAlerts = (): DplAlertContextType => {
  const context: DplAlertContextType | undefined = useContext(DplAlertContext);
  if (context === undefined) {
    throw new Error(`useDplAlerts must be used within a AlertProvider`);
  }
  return context;
};

export { DplAlertProvider, useDplAlerts };
