import React, { useContext, useEffect, useState } from "react";

type LoadingContextType = {
  loading: boolean;
  setLoading: (value: boolean) => void;
  loadingStatus: string;
  setLoadingStatus: (value: string) => void;
};

const LoadingContext = React.createContext<LoadingContextType | undefined>(undefined);

const LoadingProvider = ({ ...props }) => {
  const [loading, setLoading] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState("");

  return (
    <LoadingContext.Provider
      value={{
        loading,
        setLoading,
        loadingStatus,
        setLoadingStatus,
      }}
      {...props}
    />
  );
};

const useLoading = (): LoadingContextType => {
  const context: LoadingContextType | undefined = useContext<LoadingContextType | undefined>(LoadingContext);
  if (context === undefined) {
    throw new Error(`useLoading must be used within a LoadingProvider`);
  }
  return context;
};

export { LoadingProvider, useLoading };
