import { datadogRum } from "@datadog/browser-rum";
import React, { useEffect, useState } from "react";
import { getUser } from "../clients/DPLClient";
import { ORG_IDS } from "../types/Organization";
import { UserDetails, UserRoles } from "../types/UserTypes";
import { useOktaAuth } from "@okta/okta-react";
import { OktaAuth } from "@okta/okta-auth-js";

type AuthContextType = {
  oktaAuth?: OktaAuth;
  user?: UserDetails;
  initialized: boolean;
  warnedLogout: boolean;
  setWarnedLogout: (value: boolean) => void;
  isBoc: boolean;
  isClubAdmin: boolean;
  isBocClubAdmin: boolean;
  isBocAdmin: boolean;
  isMediaReviewer: boolean;
  isTaskManager: boolean;
  isTagManager: boolean;
  isTopProspectUser: boolean;
  isTopProspectAdmin: boolean;
  isUSABaseballUser: boolean;
  isQuestionnaireAuthor: boolean;
  isQuestionnaireAuthorAdvanced: boolean;
  isQuestionnaireAdmin: boolean;
  isWaiverCreator: boolean;
  isVideoTech: boolean;
  isEventAdmin: boolean;
  isEventApprover: boolean;
  isEventEditor: boolean;
  isEventDataViewer: boolean;
  isScheduleAdmin: boolean;
  isScheduleViewer: boolean;
  isScheduleMaster: boolean;
  isMedicalViewer: boolean;
  isProspectScout: boolean;
  isProspectLeagueStudyAdmin: boolean;
  isProspectTeamStudyAdmin: boolean;
  isProspectTeamStudyStaff: boolean;
  isProspectTeamStudyViewer: boolean;
  isProspectStudySuperAdmin: boolean;
  isProspectScheduleVideoTech: boolean;
  logout: () => Promise<any>;
  getUserDetails: () => void;
};

const AuthContext = React.createContext<AuthContextType | undefined>(undefined);

type AuthProviderProps = {
  oktaAuth?: OktaAuth;
  children: React.ReactNode;
};

const AuthProvider = ({ oktaAuth, children }: AuthProviderProps) => {
  const { authState } = useOktaAuth();

  const [user, setUser] = useState<UserDetails>();
  const [initialized, setInitialized] = useState<boolean>(false);
  const [warnedLogout, setWarnedLogout] = useState<boolean>(false);
  const [isBoc, setIsBoc] = useState<boolean>(false);
  const [isClubAdmin, setIsClubAdmin] = useState<boolean>(false);
  const [isBocClubAdmin, setIsBocClubAdmin] = useState<boolean>(false);
  const [isBocAdmin, setIsBocAdmin] = useState<boolean>(false);
  const [isMediaReviewer, setIsMediaReviewer] = useState<boolean>(false);
  const [isTaskManager, setIsTaskManager] = useState<boolean>(false);
  const [isTagManager, setIsTagManager] = useState<boolean>(false);
  const [isTopProspectUser, setIsTopProspectUser] = useState<boolean>(false);
  const [isTopProspectAdmin, setIsTopProspectAdmin] = useState<boolean>(false);
  const [isUSABaseballUser, setIsUSABaseballUser] = useState<boolean>(false);
  const [isQuestionnaireAuthor, setIsQuestionnaireAuthor] = useState<boolean>(false);
  const [isQuestionnaireAuthorAdvanced, setIsQuestionnaireAuthorAdvanced] = useState<boolean>(false);
  const [isQuestionnaireAdmin, setIsQuestionnaireAdmin] = useState<boolean>(false);
  const [isWaiverCreator, setIsWaiverCreator] = useState<boolean>(false);
  const [isVideoTech, setIsVideoTech] = useState<boolean>(false);
  const [isEventAdmin, setIsEventAdmin] = useState<boolean>(false);
  const [isEventApprover, setIsEventApprover] = useState<boolean>(false);
  const [isEventEditor, setIsEventEditor] = useState<boolean>(false);
  const [isEventDataViewer, setIsEventDataViewer] = useState<boolean>(false);
  const [isScheduleAdmin, setIsScheduleAdmin] = useState<boolean>(false);
  const [isScheduleViewer, setIsScheduleViewer] = useState<boolean>(false);
  const [isScheduleMaster, setIsScheduleMaster] = useState<boolean>(false);
  const [isMedicalViewer, setIsMedicalViewer] = useState<boolean>(false);
  const [isProspectScout, setIsProspectScout] = useState<boolean>(false);
  const [isProspectLeagueStudyAdmin, setIsProspectLeagueStudyAdmin] = useState<boolean>(false);
  const [isProspectTeamStudyAdmin, setIsProspectTeamStudyAdmin] = useState<boolean>(false);
  const [isProspectTeamStudyStaff, setIsProspectTeamStudyStaff] = useState<boolean>(false);
  const [isProspectTeamStudyViewer, setIsProspectTeamStudyViewer] = useState<boolean>(false);
  const [isProspectStudySuperAdmin, setIsProspectStudySuperAdmin] = useState<boolean>(false);
  const [isProspectScheduleVideoTech, setIsProspectScheduleVideoTech] = useState<boolean>(false);

  const getUserDetails = async () => {
    try {
      if (window.isNativeApp) {
        const storedUserDetailsStr = window.localStorage.getItem("userDetails");
        if (storedUserDetailsStr?.length) {
          const storedUserDetails = JSON.parse(storedUserDetailsStr);
          setUser(storedUserDetails);
          return storedUserDetails;
        }
      }
      const result: UserDetails = await getUser();
      setUser(result);
      return result;
    } catch (e) {
      console.error(e);
    }
  };

  async function logout(): Promise<any> {
    setUser(undefined);
    window.localStorage.clear();
    return Promise.resolve();
  }

  useEffect(() => {
    if (!!user?.userRole) {
      setIsBoc(user.orgId === ORG_IDS.MLB);
      setIsClubAdmin(user.userRole.includes(UserRoles.PROSPECT_CLUB_ADMIN));
      setIsBocClubAdmin(user.userRole.includes(UserRoles.PROSPECT_BOC_CLUB_ADMIN));
      setIsBocAdmin(user.userRole.includes(UserRoles.PROSPECT_BOC_ADMIN));
      setIsMediaReviewer(user.userRole.includes(UserRoles.PROSPECT_MEDIA_REVIEWER));
      setIsTaskManager(user.userRole.includes(UserRoles.PROSPECT_TASK_MGMNT));
      setIsTagManager(user.userRole.includes(UserRoles.PROSPECT_TAG_MANAGER));
      setIsTopProspectUser(user.orgId === ORG_IDS.TOP_PROSPECT);
      setIsUSABaseballUser(user.orgId === ORG_IDS.USA_BASEBALL);
      setIsTopProspectAdmin(user.userRole.includes(UserRoles.PROSPECT_BOC_ADMIN_TOP_PROSPECT));
      setIsQuestionnaireAuthor(user.userRole.includes(UserRoles.PROSPECT_QUESTIONNAIRE_AUTHOR));
      setIsQuestionnaireAdmin(user.userRole.includes(UserRoles.PROSPECT_QUESTIONNAIRE_ADMIN));
      setIsQuestionnaireAuthorAdvanced(user.userRole.includes(UserRoles.PROSPECT_QUESTIONNAIRE_AUTHOR_ADVANCED));
      setIsVideoTech(user.userRole.includes(UserRoles.PROSPECT_VIDEO_TECHNICIAN));
      setIsWaiverCreator(user.userRole.includes(UserRoles.PROSPECT_WAIVER_CREATOR));
      setIsEventAdmin(user.userRole.includes(UserRoles.PROSPECT_EVENT_ADMIN));
      setIsEventApprover(user.userRole.includes(UserRoles.PROSPECT_EVENT_APPROVER));
      setIsEventEditor(user.userRole.includes(UserRoles.PROSPECT_EVENT_EDITOR));
      setIsEventDataViewer(user.userRole.includes(UserRoles.PROSPECT_EVENT_DATA_VIEWER));
      setIsScheduleAdmin(user.userRole.includes(UserRoles.PROSPECT_SCHEDULE_ADMIN));
      setIsScheduleViewer(user.userRole.includes(UserRoles.PROSPECT_SCHEDULE_VIEWER));
      setIsScheduleMaster(user.userRole.includes(UserRoles.PROSPECT_SCHEDULE_MASTER));
      setIsMedicalViewer(user.userRole.includes(UserRoles.PROSPECT_VIEW_MEDICAL));
      setIsProspectScout(user.userRole.includes(UserRoles.PROSPECT_SCOUT));
      setIsProspectLeagueStudyAdmin(user.userRole.includes(UserRoles.PROSPECT_LEAGUE_STUDY_ADMIN));
      setIsProspectTeamStudyAdmin(user.userRole.includes(UserRoles.PROSPECT_TEAM_STUDY_ADMIN));
      setIsProspectTeamStudyStaff(user.userRole.includes(UserRoles.PROSPECT_TEAM_STUDY_STAFF));
      setIsProspectTeamStudyViewer(user.userRole.includes(UserRoles.PROSPECT_TEAM_STUDY_VIEWER));
      setIsProspectStudySuperAdmin(user.userRole.includes(UserRoles.PROSPECT_STUDY_SUPER_ADMIN));
      setIsProspectScheduleVideoTech(user.userRole.includes(UserRoles.PROSPECT_SCHEDULE_VIDEO_TECH));
    } else {
      setIsBoc(false);
      setIsClubAdmin(false);
      setIsBocClubAdmin(false);
      setIsBocAdmin(false);
      setIsMediaReviewer(false);
      setIsTaskManager(false);
      setIsTagManager(false);
      setIsTopProspectUser(false);
      setIsUSABaseballUser(false);
      setIsTopProspectAdmin(false);
      setIsQuestionnaireAuthor(false);
      setIsQuestionnaireAdmin(false);
      setIsQuestionnaireAuthorAdvanced(false);
      setIsVideoTech(false);
      setIsWaiverCreator(false);
      setIsEventAdmin(false);
      setIsEventApprover(false);
      setIsEventEditor(false);
      setIsEventDataViewer(false);
      setIsScheduleAdmin(false);
      setIsScheduleViewer(false);
      setIsScheduleMaster(false);
      setIsMedicalViewer(false);
      setIsProspectScout(false);
      setIsProspectLeagueStudyAdmin(false);
      setIsProspectTeamStudyAdmin(false);
      setIsProspectTeamStudyStaff(false);
      setIsProspectTeamStudyViewer(false);
      setIsProspectStudySuperAdmin(false);
      setIsProspectScheduleVideoTech(false);
    }
  }, [user]);

  useEffect(() => {
    if (!!user && !!user.id) {
      datadogRum.setUser({
        id: user.id.toString(),
        email: user.email,
        name: `${user.lastName}, ${user.firstName}`,
      });
    } else {
      datadogRum.removeUser();
    }
  }, [user]);

  useEffect(() => {
    if (authState?.isAuthenticated !== undefined) {
      if (authState?.isAuthenticated) {
        // when the user becomes authenticated, call onLogin() to populate AuthContext's user info
        void getUserDetails();
      } else {
        setInitialized(true);
      }
    }
  }, [authState]);

  return (
    <AuthContext.Provider
      value={{
        oktaAuth,
        user,
        initialized,
        warnedLogout,
        setWarnedLogout,
        isBoc,
        isClubAdmin,
        isBocClubAdmin,
        isBocAdmin,
        isMediaReviewer,
        isTaskManager,
        isTagManager,
        isTopProspectUser,
        isTopProspectAdmin,
        isUSABaseballUser,
        isQuestionnaireAuthor,
        isQuestionnaireAuthorAdvanced,
        isQuestionnaireAdmin,
        isVideoTech,
        isWaiverCreator,
        isEventAdmin,
        isEventApprover,
        isEventEditor,
        isEventDataViewer,
        isScheduleAdmin,
        isScheduleViewer,
        isScheduleMaster,
        isMedicalViewer,
        isProspectScout,
        isProspectLeagueStudyAdmin,
        isProspectTeamStudyAdmin,
        isProspectTeamStudyStaff,
        isProspectTeamStudyViewer,
        isProspectStudySuperAdmin,
        isProspectScheduleVideoTech,
        logout,
        getUserDetails,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = (): AuthContextType => {
  const context: AuthContextType | undefined = React.useContext<AuthContextType | undefined>(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
};

export { AuthProvider, useAuth };
