import React, { useContext, useReducer } from "react";
import { AlertConstants } from "../constants/AlertConstants";
import { randomString } from "../util/StringUtil";

export type SiteAlert = {
  id?: string;
  type: AlertConstants;
  text: string;
  manualClose?: boolean;
};

type SiteSiteAlertContextType = {
  alerts: SiteAlert[];
  addAlert: (alert: SiteAlert) => void;
  removeAlert: (id: string) => void;
};

enum SiteAlertActionType {
  "addAlert",
  "removeAlert",
}

type SiteAlertAction =
  | { type: SiteAlertActionType.addAlert; alert: SiteAlert }
  | { type: SiteAlertActionType.removeAlert; id: string };

const getAlertId = (): string => {
  return "_" + randomString();
};

const defaultContext = {
  alerts: [],
  addAlert: (alert: SiteAlert) => console.log(alert),
  removeAlert: (id: string) => console.log(id),
};

const SiteSiteAlertContext = React.createContext<SiteSiteAlertContextType | undefined>(undefined);

const SiteAlertProvider: React.FC = ({ children }) => {
  const reducer = (state: SiteSiteAlertContextType, action: SiteAlertAction) => {
    switch (action.type) {
      case SiteAlertActionType.addAlert:
        const newAlerts = [...state.alerts];
        if (action.alert) {
          if (!action.alert.id) {
            action.alert.id = getAlertId();
          }
          newAlerts[0] = action.alert;
        }
        return { ...state, alerts: newAlerts };
      case SiteAlertActionType.removeAlert:
        const filtered = state.alerts.filter((a) => a.id !== action.id);
        return { ...state, alerts: filtered };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, defaultContext);
  const { alerts } = state;

  const addAlert = (alert: SiteAlert): void => {
    if (alert) {
      dispatch({ type: SiteAlertActionType.addAlert, alert });
    }
  };

  const removeAlert = (id: string): void => {
    if (id) {
      dispatch({ type: SiteAlertActionType.removeAlert, id });
    }
  };

  return (
    <SiteSiteAlertContext.Provider value={{ alerts, addAlert, removeAlert }}>{children}</SiteSiteAlertContext.Provider>
  );
};

const useSiteAlerts = (): SiteSiteAlertContextType => {
  const context: SiteSiteAlertContextType | undefined = useContext(SiteSiteAlertContext);
  if (context === undefined) {
    throw new Error(`useSiteAlerts must be used within a SiteAlertProvider`);
  }
  return context;
};

export { SiteAlertProvider, useSiteAlerts };
